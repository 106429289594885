import React from "react";
import {Text, RichText, Image} from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import {getRelativePaths} from '../../utils/Utils.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselHome.scss"
import { handleSliderButtonClick } from "../Tagging";

class CarouselHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      activeDot: 0
    };
    this.setActiveDot = this.setActiveDot.bind(this);
  }

  setActiveDot(dot) {
    this.setState({
      activeDot: dot
    });
  }

  componentDidMount() {
    // GTM
    document.querySelectorAll('.slick-arrow').forEach(() => addEventListener('click', handleSliderButtonClick));
    //document.querySelectorAll('.slick-dots li').forEach(() => addEventListener('click', handleSliderButtonClick));
  }
 

  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }
      ]
    };

    const slidesList = this.props.fields.Slides;

    let dots = [];
    for (let i = 0; i <= slidesList.length; i++) {
      dots.push(
          <div className={"owl-dot" + this.state.activeDot === i ? "selected" : ""}
               key={i}
               onClick={() => this.setActiveDot(i)}>
          </div>
      );
    }

    const designVariant = this.props.fields.designVariant === undefined ? "" : this.props.fields.designVariant && this.props.fields.designVariant.value;

    return (
        <div className={`section ${designVariant}`}>
          <h1 style={{height: "0", overflow: "hidden"}}>Les déodorants DOP, made in France</h1>
          <div className="container-fluid home-slider-container">
            <div className="row homeCarousel">
              <Slider {...settings} style={{maxHeight: '505px'}}>
                {slidesList &&
                slidesList.map((listItem, index) => (
                    <li key={`listItem-${index}`} >
                      <div
                          className="banner"
                          style={{
                          backgroundImage:
                              `url(` + listItem.fields.Banner.value.src + `)`
                      }}>
                        <div className="text-wrapper">
                          <div className="text-blue">
                            <div className="tp-caption business_small_white tp-resizeme">
                              <Text field={listItem.fields.Subtitle} />
                            </div>
                          </div>
                          <div>
                            <div className="tp-caption business_big_white tp-resizeme">
                              <RichText field={listItem.fields.Title} />
                            </div>
                          </div>
                          <div className="button-wrapper">
                            {listItem.fields.ButtonLeft &&
                            listItem.fields.ButtonLeft.value.href !== "" && (
                                <div className="button-wrapper__link-container">
                                  <NavLinkAdv className="btn btn-white-outline btn--transparent" to={getRelativePaths(listItem.fields.ButtonLeft.value.href)}>{listItem.fields.ButtonLeft.value.text}</NavLinkAdv>
                                </div>
                            )}
                            {listItem.fields.ButtonRight &&
                            listItem.fields.ButtonRight.value.href !== "" && (
                                <div>
                                  <NavLinkAdv className="btn btn-white-outline btn--transparent" to={getRelativePaths(listItem.fields.ButtonRight.value.href)}>{listItem.fields.ButtonRight.value.text}</NavLinkAdv>
                                </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                ))}
              </Slider>
            </div>
          </div>
        </div>
    );
  }
}

export default CarouselHome;
