import React from 'react';
import {
  Text,
  Image,
  RichText,
  Link,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';
import './ThreeColumnsPushes.scss';
import ReactComponent from '../../ReactComponent';

class FeaturedPosts extends ReactComponent {
  render() {
    const datasource = this.props.fields || {};

    if (
      datasource !== undefined &&
      Object.getOwnPropertyNames(datasource).length >= 1
    ) {
      const { ThreeColumnsPushesContentList, designVariant } =
        this.props.fields;

      const Title = (listItem) => {
        return <Text field={listItem} tag={'h3'} />;
      };

      return (
        <React.Fragment>
          <div className={'section ThreeColumnsPushes ' + designVariant.value}>
            <div className="first-part">
              <div className="row">
                <div className="contentTitle">
                  <Image
                    loading="lazy"
                    field={this.props.fields && this.props.fields.Logo}
                  />
                  <Text
                    field={this.props.fields && this.props.fields.Title}
                    tag={'h2'}
                    className={'title'}
                  />
                  <Text
                    field={this.props.fields && this.props.fields.SubTitle}
                    tag={'span'}
                    className={'subTitle'}
                  />
                </div>
              </div>
            </div>
            <div className="second-part">
              <div className="row">
                {ThreeColumnsPushesContentList &&
                  ThreeColumnsPushesContentList.map((listItem, index) => (
                    <div className="contents" key={`sharedListItem-${index}`}>
                      <NavLinkAdv
                        to={
                          listItem.fields.PostLink &&
                          listItem.fields.PostLink.value.href
                        }>
                        {!designVariant.value && Title(listItem.fields.title)}
                        <div className="text-center">
                          <Image loading="lazy" field={listItem.fields.Icon} />
                        </div>
                        {designVariant.value && Title(listItem.fields.title)}
                        <RichText
                          field={listItem.fields.content}
                          tag={'div'}
                          className={'content'}
                        />
                      </NavLinkAdv>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}
export default withSitecoreContext()(FeaturedPosts);
