import React from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import { getRelativePaths } from "../../utils/Utils.js";
import { HashLink as HLink } from "react-router-hash-link";
import "./PushItem.scss";
class PushItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackgroundCover: { display: "none" }
    };
  }

  componentDidMount() {
    if (this.props !== null) {
      if (this.props.fields.BackgroundImage !== null) {
        this.setState({
          showBackgroundCover: {
            backgroundImage:
              "url(" + this.props.fields.BackgroundImage.value.src + ")"
          }
        });
      }
    }
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    const scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        behavior: "smooth"
      });
    };
    return (
      <HLink
        to={getRelativePaths(this.props.fields.PushItemLink.value.href)}
        scroll={el => scrollWithOffset(el, 5)}
        className={designVariant}>
        <div className="col-sm-6 col-lg-6 col-md-6 pr-0 pl-0 counter-wrapper">
          <div className="counter-block__inner">
            <div
              className="counter-block"
              style={this.state.showBackgroundCover}>
              <div className="counter counter-icon-top">
                <span data-wow-delay="0.3s" className="counter-icon wow zoomIn">
                  <Image field={this.props.fields.Picto} />
                </span>
                <div className="counter-count">
                  <span
                    className="counter-number"
                    data-from="0"
                    data-to="750"
                    data-speed="5000">
                    <Text
                      field={this.props.fields && this.props.fields.Title}
                    />
                  </span>
                </div>
                <div className="counter-text">
                  <span className="counter-message">
                    <Text
                      field={this.props.fields && this.props.fields.Description}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HLink>
    );
  }
}

export default PushItem;
