import React from "react";
import {
  Text,
  RichText,
  Image
} from "@sitecore-jss/sitecore-jss-react";
import './RowBlock.scss';

class RowBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var leftcssStyle = 'col-md-6 c-l img-cn';
    var rightcssStyle = 'col-md-6 c-r text-cn';

    if (this.props.fields.blockStyle !== null) {
      leftcssStyle = this.props.fields.blockStyle.fields.leftcssStyle.value;
      rightcssStyle = this.props.fields.blockStyle.fields.rightcssStyle.value;
    }

    if (leftcssStyle.includes('text-cn')) {
      return (
        <div
          className={
            'RowBlock item-row__wrapper ' +
            this.props.fields.designVariant.value
          }
          id={
            this.props.fields.blockAnchorID &&
            this.props.fields.blockAnchorID.value
          }>
          <div
            className={`${
              this.props.fields.blockStyle !== null &&
              rightcssStyle == 'col-md-6 c-r img-cn text-center'
                ? 'row item__row__custom inverse__custom'
                : 'row item__row__custom'
            }`}>
            <div className="text-container">
              <div className={leftcssStyle}>
                <div>
                  <div>
                    <h2>
                      <Text
                        field={
                          this.props.fields && this.props.fields.blockTitle
                        }
                      />
                    </h2>
                    <RichText
                      field={
                        this.props.fields && this.props.fields.blockContent
                      }
                      className="helv_lt"
                    />
                  </div>
                </div>
              </div>
              <div className={rightcssStyle}>
                <img
                  src={
                    this.props.fields &&
                    this.props.fields.blockImage &&
                    this.props.fields.blockImage.value.src
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            'RowBlock item-row__wrapper ' +
            this.props.fields.designVariant.value
          }
          id={
            this.props.fields.blockAnchorID &&
            this.props.fields.blockAnchorID.value
          }>
          <div
            className={`${
              this.props.fields.blockStyle !== null &&
              rightcssStyle == 'col-md-6 c-r img-cn text-center'
                ? 'row item__row__custom inverse__custom'
                : 'row item__row__custom'
            }`}>
            <div className="text-container">
              <div className={leftcssStyle}>
                <img
                  src={
                    this.props.fields &&
                    this.props.fields.blockImage &&
                    this.props.fields.blockImage.value.src
                  }
                />
              </div>
              <div className={rightcssStyle}>
                <div>
                  <div>
                    <h2>
                      <Text
                        field={
                          this.props.fields && this.props.fields.blockTitle
                        }
                      />
                    </h2>
                    <RichText
                      field={
                        this.props.fields && this.props.fields.blockContent
                      }
                      className="helv_lt"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default RowBlock;
