import React from 'react';
import { Text, Image, withSitecoreContext, isExperienceEditorActive, RichText } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import './carouselProduct.scss';
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-images';

import ProductReviews from '../../utils/ProductReviews/index.js';
import './ProductSummary.scss';
import ProductPIL from '../ProductPIL';

import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/core';
import i18next from 'i18next';
import { handleClickToBuy, handleWriteReview } from '../Tagging';

const override = css`
  margin: auto;
`;

const RatingsReviewQuery = gqlLoader('./query.graphql');

class ProductSummary extends ReactComponent {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      photoIndex: 0,
      isOpen: false,
      showMessage: '',
    };
  }

  componentDidMount() {
    // scroll fix
    window.scrollTo(0, 0);
  }

  handleChange(event) {
    this.setState({ isOpen: true, photoIndex: event });
  }
  onClose() {
    this.setState({ isOpen: false });
  }
  gotoNext() {
    this.setState({ photoIndex: this.state.photoIndex + 1 });
  }
  gotoPrevious() {
    this.setState({ photoIndex: this.state.photoIndex - 1 });
  }

  onClickThumbnail(event) {
    this.setState({ photoIndex: event });
  }

  handleToggleAccordion(e) {
    const selectedParent = e.currentTarget.parentElement.parentElement.parentElement;
    selectedParent.classList.toggle('open');
    document.querySelectorAll('.card').forEach((elem) => {
        if (elem !== selectedParent) elem.classList.remove('open');
    });
  }

  render() {
    const { isOpen } = this.state;

    let carouselImages = [];
    if (
      this.props.sitecoreContext.route.fields.image1 &&
      this.props.sitecoreContext.route.fields.image1.value &&
      this.props.sitecoreContext.route.fields.image1.value.src &&
      this.props.sitecoreContext.route.fields.image1.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={1}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image1.value.src} alt="" />
        </div>
      );
    }
    if (
      this.props.sitecoreContext.route.fields.image2 &&
      this.props.sitecoreContext.route.fields.image2.value &&
      this.props.sitecoreContext.route.fields.image2.value.src &&
      this.props.sitecoreContext.route.fields.image2.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={2}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image2.value.src} alt="" />
        </div>
      );
    }
    if (
      this.props.sitecoreContext.route.fields.image3 &&
      this.props.sitecoreContext.route.fields.image3.value &&
      this.props.sitecoreContext.route.fields.image3.value.src &&
      this.props.sitecoreContext.route.fields.image3.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={3}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image3.value.src} alt="" />
        </div>
      );
    }
    if (
      this.props.sitecoreContext.route.fields.image4 &&
      this.props.sitecoreContext.route.fields.image4.value &&
      this.props.sitecoreContext.route.fields.image4.value.src &&
      this.props.sitecoreContext.route.fields.image4.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={4}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image4.value.src} alt="" />
        </div>
      );
    }

    let imageArray = [];

    if (this.props.sitecoreContext.route.fields.image1.value && this.props.sitecoreContext.route.fields.image1.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image1.value.src,
      });
    }

    if (this.props.sitecoreContext.route.fields.image2.value && this.props.sitecoreContext.route.fields.image2.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image2.value.src,
      });
    }

    if (this.props.sitecoreContext.route.fields.image3.value && this.props.sitecoreContext.route.fields.image3.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image3.value.src,
      });
    }

    if (this.props.sitecoreContext.route.fields.image4.value && this.props.sitecoreContext.route.fields.image4.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image4.value.src,
      });
    }

    //
    var productId = this.props.sitecoreContext.route.itemId;
    productId = productId.split('-').join('').toLowerCase();

    return (
      <>
        <Query query={RatingsReviewQuery} variables={{ productId: productId, indexname: this.indexName }}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                  <DotLoader css={override} sizeUnit={'px'} size={50} color={'#0a4f91'} loading={!this.state.isLoaded} />
                </div>
              );

            if (error) return <div>Error: {error.message}</div>;

            const productData = this.props.sitecoreContext.route.fields;

            return (
              <>
                {data &&
                  data.product.results.items.map((product, keyIndex) => (
                    <React.Fragment key={keyIndex}>
                      <div className={`row summary-container single-product`}>
                        <div className="col-sm-6 entry-image">
                          <div className="single-product-images single-product__media">
                            {isExperienceEditorActive() && (
                              <div className="product-thumbnail">
                                <img
                                  src={
                                    productData &&
                                    productData.image1 &&
                                    productData.image1.src
                                  }
                                />
                                <img
                                  src={
                                    productData &&
                                    productData.image2 &&
                                    productData.image2.src
                                  }
                                />
                                <img
                                  src={
                                    productData &&
                                    productData.image3 &&
                                    productData.image3.src
                                  }
                                />
                                <img
                                  src={
                                    productData &&
                                    productData.image4 &&
                                    productData.image4.src
                                  }
                                />
                              </div>
                            )}
                            {!isExperienceEditorActive() &&
                              imageArray.length > 0 &&
                              productData.image1 &&
                              productData.image1.value &&
                              productData.image1.value.src !== '' && (
                                <Carousel
                                  showStatus={false}
                                  showIndicators={false}
                                  onClickItem={this.handleChange.bind(this)}
                                  showArrows={imageArray.length > 1 ? true : false}>
                                  {carouselImages}
                                </Carousel>
                              )}
                            {isOpen && (
                              <Lightbox
                                images={imageArray}
                                isOpen={this.state.isOpen}
                                onClickPrev={this.gotoPrevious}
                                onClickNext={this.gotoNext}
                                onClose={this.onClose}
                                backdropClosesModal={true}
                                currentImage={this.state.photoIndex}
                                enableKeyboardInput={true}
                                showImageCount={false}
                                showThumbnails={true}
                                onClickThumbnail={this.onClickThumbnail.bind(this)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6 entry-summary">
                          <div className="summary">
                            <h1 className="single-product__title">
                              <Text field={productData && productData.title} />
                            </h1>
                            <h2 className="single-product__sub-title">
                              <Text field={productData && productData.beneficeProduit} />
                            </h2>

                            <div className=" single-product__rating">
                              {product.item.rating && product.item.rating.value != null && product.item.rating.value != 0 && (
                                <div className="single-product__rating__stars">
                                  <a href="#reviews-container">
                                    <StarRating value={product.item.rating.value} />
                                  </a>
                                  <ProductReviews productId={product.item.id} />
                                </div>
                              )}
                              <div className="single-product__rating__cta">
                                <a
                                  onClick={() => handleWriteReview(productData.title.value, product.item.codeEan.value)}
                                  href="#review_form_wrapper">
                                  Ajouter un commentaire
                                </a>
                              </div>
                            </div>

                            <div className="product-excerpt single-product__exerpt">
                              <RichText
                                field={productData && productData.productShortDescription}
                              />
                            </div>
                            <div className="single-product__cta">
                              <a
                                onClick={() => handleClickToBuy(productData.title.value, product.item.codeEan.value)}
                                data-eans={product.item && product.item.codeEan.value}
                                className="swn-tag-wtb-btn btn btn_custom_oro button">
                                {i18next.t('Acheter')}
                              </a>
                            </div>

                            <div className="accordion" id="product_accordion">
                              
                              <div className="card">
                                <div className="card-header" id="headingOne">
                                  <h2 className="mb-0 mt-0 mt-0">
                                    <button
                                      onClick={(e) => this.handleToggleAccordion(e)}
                                      className="btn-link collapsed text-uppercase text-left mr-2"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#Details"
                                      aria-expanded="false"
                                      aria-controls="Details">
                                      {i18next.t('DetailsLabel')}
                                    </button>
                                  </h2>
                                </div>
                                <div id="Details" className="collapse" aria-labelledby="headingOne" data-parent="#product_accordion">
                                  <div className="card-body">
                                    <span className="double_cat">
                                      <RichText field={productData && productData.productLongDescription} tag="span" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingTwo">
                                  <h2 className="mb-0 mt-0">
                                    <button
                                      onClick={(e) => this.handleToggleAccordion(e)}
                                      className="btn-link collapsed text-uppercase text-left mr-2"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#use"
                                      aria-expanded="false"
                                      aria-controls="use">
                                      {i18next.t('HowToUseLabel')}
                                    </button>
                                  </h2>
                                </div>
                                <div id="use" className="collapse" aria-labelledby="headingTwo" data-parent="#product_accordion">
                                  <div className="card-body">
                                    <RichText field={productData && productData.howtouse} />
                                  </div>
                                </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="headingThree">
                                  <h2 className="mb-0 mt-0">
                                    <button
                                      onClick={(e) => this.handleToggleAccordion(e)}
                                      className="btn-link collapsed text-uppercase text-left mr-2"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#ingredient"
                                      aria-expanded="false"
                                      aria-controls="ingredient">
                                      {i18next.t('IngredientsLabel')}
                                    </button>
                                  </h2>
                                </div>
                                <div id="ingredient" className="collapse" aria-labelledby="headingThree" data-parent="#product_accordion">
                                  <div className="card-body">
                                    <RichText field={productData && productData.productIngredients} />
                                  </div>
                                </div>
                              </div>
                              {/*---------------- PIL -------------*/}
                              {this.props.sitecoreContext.route.fields.GlobalFootprintScore && this.props.sitecoreContext.route.fields.GlobalFootprintScore.value && (
                                <div className="card">
                                  <div className="card-header" id="headingFour">
                                    <h2 className="mb-0 mt-0">
                                      <button
                                        onClick={(e) => this.handleToggleAccordion(e)}
                                        className="btn-link collapsed text-uppercase text-left mr-2"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#pil"
                                        aria-expanded="false"
                                        aria-controls="pil">
                                        {i18next.t('PIL-general-title')}
                                      </button>
                                    </h2>
                                  </div>
                                  <div id="pil" className="collapse" aria-labelledby="headingFour" data-parent="#product_accordion">
                                    <div className="card-body">
                                      <ProductPIL />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {productData && productData.environmentText.value !== '' && (
                                <div className="card">
                                  <div className="card-header" id="headingFive">
                                    <h2 className="mb-0 mt-0">
                                      <button
                                        onClick={(e) => this.handleToggleAccordion(e)}
                                        className="btn-link collapsed text-uppercase text-left mr-2"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#environment"
                                        aria-expanded="false"
                                        aria-controls="environment">
                                        {i18next.t('EnvironmentLabel')}
                                      </button>
                                    </h2>
                                  </div>
                                  <div id="environment" className="collapse" aria-labelledby="headingFive" data-parent="#product_accordion">
                                    <div className="card-body">
                                      <RichText field={productData && productData.environmentText} />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </>
            );
          }}
        </Query>
        {this.props.sitecoreContext.route.fields.videoID.value !== '' && (
          <div className="videoContainer">
            <iframe
              src={'https://www.youtube.com/embed/' + this.props.sitecoreContext.route.fields.videoID.value}
              description={this.props.sitecoreContext.route.fields.videoMetaDescription.value}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
        )}
      </>
    );
  }
}

export default withSitecoreContext()(ProductSummary);
