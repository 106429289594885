import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InstagramCarouselItem from "../InstagramCarouselItem";
import "./InstagramCarousel.scss";
import {loader as gqlLoader} from "graphql.macro";
import { Query } from "react-apollo";
import ReactComponent from "../../ReactComponent";
import {Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const LatestPostsQuery = gqlLoader('./GetInstagramImages.graphql');

class InstagramCarousel extends ReactComponent {

  constructor(props) {
    super(props);
    const datasource = this.props.fields || {};
    if(datasource != undefined && Object.getOwnPropertyNames(datasource).length >= 1)
    {
      this.state = {
        numberOfImagesToShow: props.fields.NumberOfImages.value,
        numberOfImagesToLoad: props.fields.NumberOfImagesToLoad.value
      };
    }
  }

  render() {
    const datasource = this.props.fields || {};
    if(datasource != undefined && Object.getOwnPropertyNames(datasource).length >= 1)
    {
    const { numberOfImagesToShow, numberOfImagesToLoad} = this.state;
    const settings = {
      centerMode: true,
      centerPadding: '60px',
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: parseInt(numberOfImagesToShow),
      slidesToScroll: 1,
      draggable: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

      return (
        <div className={"row insta"}>
          <div className="col-md-12 text-center isMob">
            <Text field={this.props.fields && this.props.fields.Title} tag="h2" />
          </div>
          <Query query={LatestPostsQuery} variables={{rootItem: '/sitecore/content/' + this.appName + '/', index: this.indexName}}>
              {({ loading, error, data}) => {
                if (loading)
                  return (
                    <div>Loading: {loading.message}</div>
                  );
                if (error)
                  return (
                      <div>Error: {error.message}</div>
                  );
                return (
                    <div className="instagram-recent-media">
                      <Slider {...settings} >
                        {data && data.LatestPostsList && data.LatestPostsList.results &&
                          data.LatestPostsList.results.items
                          .filter(o => o)
                          .sort((x,y) => {
                            var first = x && x.item && x.item.imageTimestamp && x.item.imageTimestamp.value;
                            var second = y && y.item && y.item.imageTimestamp && y.item.imageTimestamp.value
                            return(
                              (first === second) ? 0 : ((first <  second) ? 1 : -1 )
                            )
                            
                          })
                          .slice(0, numberOfImagesToLoad).map((post, index) => (
                            <div key={index}>
                              <InstagramCarouselItem
                                item={post.item}
                              />
                            </div>
                          ))
                        }
                      </Slider>
                    </div>
                ) 
              }}
              </Query>
          </div>
        );
    }
    else
    {
      return (
      <div className="component-no-datasource">No datasource for {this.props.rendering.componentName} component</div>
        )
    }
    
   
    
  }
}

export default withSitecoreContext()(InstagramCarousel);
