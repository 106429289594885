import React from "react";
import {
  Text,
  RichText,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import i18next from 'i18next';
import "./ProductCharacteristics.scss";
import Flag from '../../assets/ProductCharacteristics/flag.png';

class ProductCharacteristics extends React.Component {
  render() {
    return (
      <div className="tabbable commerce-tabs single-commerce-tabs">
        <div className="tab-content">
          
                <div
                  id={`tab-0`}
                  className="single-commerce-tabs__content"
                  >
                  <div className="product-description">
                    <h3 className="single-commerce-tabs__title">
                      {i18next.t("product-description")}
                    </h3>
                    <div className="single-commerce-tabs__desc">
                      <RichText
                        field={
                          this.props.sitecoreContext.route.fields
                            .productLongDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="ldi-decryptee">
                    <h3 className="single-commerce-tabs__title">
                      {i18next.t("product-ldi-decryptee")}
                    </h3>
                    <div className="single-commerce-tabs__desc">
                      <RichText
                        field={
                          this.props.sitecoreContext.route.fields
                            .productIngredients
                        }
                      />
                    </div>
                  </div>
                </div>
              
        </div>
        <div className="col-md-12 text-center">
          <img src={Flag} alt=""/>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(ProductCharacteristics);
