import React from "react";
import { Placeholder, withPlaceholder } from "@sitecore-jss/sitecore-jss-react";
import "./ListOfPush.scss";
const ListOfPush = props => {
  
  return (
    <div className="section counter-block__container">
      <div className="container-fluid">
        <div className="row">
          <Placeholder
            name="push-items-placeholder"
            rendering={props.rendering}
          />
        </div>
      </div>
    </div>
  );
};

export default withPlaceholder("push-items-placeholder")(ListOfPush);
